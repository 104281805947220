import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import trnd from "../../assets/img/dashboard/icons/tokend-icon-1.svg";
import ReactSpeedometer from "react-d3-speedometer";
import busd from "../../assets/img/dashboard/icons/tticon.svg";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import icon1 from "../../assets/img/dashboard/img/rank-icon1.png"
import icon2 from "../../assets/img/dashboard/img/rank-icon2.png"
import icon3 from "../../assets/img/dashboard/img/rank-icon3.png"

import {
  getRBEarning,
  getUniLevelEarning,
} from "../../ContractAction/EVCNFTContractAction";
import {
  getRemainingRbEvcValue,
  getClaimedRbEvcValue,
  getRbClaimPerc,
  setClaimRB,
} from "../../ContractAction/PoolContractAction";
import {
  BaseExplorerUrl,
  VIEWERADPORTAL,
  AUTHUSERNAME,
  AUTHUSERPASSWORD, JAVAADDSCOUNT
} from "../../ContractAction/ContractDependency";

//dev: start RankBonus Page
const useStyles = makeStyles({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  },
});

const RankBonus = ({ title }) => {
  //dev: Get the address for local storage
  const [RBEarningBUSDValue, setRBEarningBUSDValue] = useState(0);
  const [uniLevelEarningValue, setUniLevelEarningValue] = useState(0);
  const [remainingEvcRbValue, setRemainingEvcRbValue] = useState(0);
  const [RBClaimedEVCValue, setRBClaimedEVCValue] = useState(0);
  const [RbClaimPerc, setRbClaimPerc] = useState(0);
  const [overallRewardUSDC, setOverallRewardUSDC] = useState(0);
  const [segmentColors, setSegmentColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [adsStatus, setAdsStatus] = useState(
    JSON.parse(localStorage.getItem("adsStatus")) || false
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const setClaimrB = async () => {
    console.log("remainingEvcRbValue", remainingEvcRbValue);
    if (remainingEvcRbValue < 0) {
      alert("Wait until the next claimable timing");
    } else {
      try {
        const txHash = await setClaimRB();
        if (txHash?.status === 1) {
          fetchAddtrue(); 
        }
     if (txHash?.transactionHash) {
          handleTxhashShow(txHash.transactionHash);
        } else {
          toast.error("Transaction Cancelled");
        }
      } catch (error) {
        console.error("Error during transaction:", error);
      }
    }
    

  };

  const handleTxhashShow = async (e) => {
    toast.success(
      <div>
        Transaction Receipt: <br />
        <a
          href={`${BaseExplorerUrl}tx/${e}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Block Explorer
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const fetchSegmentColors = async () => {
    try {
      const percValue = await getRbClaimPerc();
      const totalSegments = 10; // Total number of segments
      const coloredSegments = Math.ceil((percValue / 100) * totalSegments);
      const newSegmentColors = Array(totalSegments).fill("#FFFFFF1F");
      for (let i = 0; i < coloredSegments; i++) {
        newSegmentColors[i] = "#306FFF";
      }
      setSegmentColors(newSegmentColors);
    } catch (error) {
      console.error("Error fetching segment colors:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch segment colors when the component mounts
    fetchSegmentColors();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const RBBUSDInfo = await getRBEarning();
      const uniLevelInfo = await getUniLevelEarning();
      setUniLevelEarningValue(uniLevelInfo);
      setOverallRewardUSDC(Number(RBBUSDInfo) + Number(uniLevelInfo));
      setRBEarningBUSDValue(Number(RBBUSDInfo));
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const RBBUSDInfo = await getRBEarning();
      setRBEarningBUSDValue(RBBUSDInfo);
      const uniLevelInfo = await getUniLevelEarning();
      setUniLevelEarningValue(uniLevelInfo);
      setOverallRewardUSDC(Number(RBBUSDInfo) + Number(uniLevelInfo));
      const RBEVCInfo = await getClaimedRbEvcValue();
      setRBClaimedEVCValue(Number(RBEVCInfo).toFixed(4));
      const remainingRbInfo = await getRemainingRbEvcValue();
      setRemainingEvcRbValue(Number(remainingRbInfo).toFixed(4));
      const rbClaimPerc = await getRbClaimPerc();
      // setRbClaimPerc(Number(rbClaimPerc));

      if (remainingRbInfo == 0) {
        setRbClaimPerc(0);
      } else {
        setRbClaimPerc(Number(rbClaimPerc));
      }
    };
    getData();
  }, []);

  useEffect(() => {
    document.title = title ? title : "TT Avatars | Rank Bonus";
    document.querySelector(".page-title").innerText = "Rank Bonus";
  }, []);

  //21 ads
  const fetch21Ads = async () => {
    try {
      const walletAddress = localStorage.getItem("connectedAccount");
      const response = await axios.get(
        `${JAVAADDSCOUNT}${walletAddress}`,
        {
          auth: {
            username: AUTHUSERNAME,
            password: AUTHUSERPASSWORD
          }
        }
      );
      if (response?.data?.statusCode === 200) {
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
        setAdsStatus(localStorage.getItem("adsStatus"));
        return response.data;
      }
      if (response?.data?.errorCode === 404) {
        setAdsStatus(false);
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
      }
    } catch (error) {
    }
  };

  const { data: adsData } = useQuery({ queryKey: ['adsData'], queryFn: fetch21Ads })
  console.log(adsData, 'adsDataadsData');
  const fetchAddtrue = async () => {
    const note = true;
    try {
      const walletAddress = localStorage.getItem("connectedAccount");
      const response = await axios.get(
        `${JAVAADDSCOUNT}${walletAddress}?note=${note}`
      );
      if (response?.data?.statusCode === 200) {
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
        setAdsStatus(localStorage.getItem("adsStatus"));
        fetch21Ads();
      }
      if (response?.data?.errorCode === 404) {
        toast.success(response?.data?.developermsg);
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
      }
    } catch (error) {
      console.log("Error fetching ads:", error);
    }
  };

  return (
    <div style={{ background: "#201f24" }}>
      <div className="dashboard-wrap">
        <div className="dash-content-area mt-1">
          <div className="container-fluid">
            <div className="d-flex justify-content-center row g-2">
              <div className="col-md-6 col-xl-4">
                <div className="dash-global-wrap" style={{ width: 'auto', height: 'auto' }}>
                  <div className="p-3 p-md-4">
                    <p className="rank-bonus line-effect text-muted">
                      {t("Total Rewards (Unilevel + RB) Earned")} (USDC)
                    </p>
                    <div className="d-flex flex-row justify-content-between mb-1">
                      <div className="d-flex align-items-center gap-2 col-3 mb-5">
                        {console.log(busd, 'busdsgfjsfgsdfgshgfhsd')
                        }
                        <img src={busd} alt="" className="img-fluid" />
                        <span className="text-md opacity-75">USDC</span>
                      </div>
                      <div className="fs-4 font-gilroy font-semibold">
                        {overallRewardUSDC}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dash-global-wrap align-item-center justify-content-center mt-3" style={{ width: 'auto', height: 'auto' }}>
                  <div className="p-3 p-md-4">
                    <p className="rank-bonus line-effect text-muted">
                      {t("Total Rank Bonus Commission Earned")} (TRND)
                    </p>
                    <div className="d-flex flex-row justify-content-between mb-1">
                      <div className="d-flex align-items-center gap-1 col-3 mb-5">
                        <img src={trnd} alt="" height='25px' width='34px' />
                        <span className="text-md opacity-75">TRND</span>
                      </div>
                      <div className="fs-4 font-gilroy font-semibold">
                        {remainingEvcRbValue}
                      </div>
                    </div>
                    <button
                      className="btn btn-primary-bg-custom btn-sm fs-6"
                      onClick={() => {
                        if (localStorage.getItem("adsStatus") === "true") {
                          setClaimrB()
                        }
                        else {
                          alert("To Claim Watch Ad's first");
                        }
                      }}
                      disabled={
                        remainingEvcRbValue > 0 ? false : true && localStorage.getItem("adsStatus") === "false"
                      }
                      style={{
                        background: "transparent",
                        border: "1px solid",
                        width: "18vw",
                        borderRadius: "8px",
                        backgroundColor: "rgba(29, 42, 42, 1)",
                        borderColor: "#25a98e",
                        borderImageSlice: 1,
                        marginTop: "-30px",
                        width: "100%",
                        color: "#25a98e",
                      }}
                    >
                      {t("Claim")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-xl-8 mt-5">
                <div className="d-flex flex-column align-items-center rank-bonus-btns">
                  {loading ? (
                    <div className="loader">Loading...</div>
                  ) : (
                    <ReactSpeedometer
                      height={200}
                      width={270}
                      needleHeightRatio={0.7}
                      endColor={"red"}
                      maxValue={100}
                      needleColor="#fff"
                      customSegmentStops={[
                        0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                      ]}
                      segmentColors={[
                        "#A7F3D0",
                        "#A7F3D0",
                        "#A7F3D0",
                        "#34D399",
                        "#34D399",
                        "#34D399",
                        "#34D399",
                        "#059669",
                        "#059669",
                        "#059669",
                      ]}
                      value={RbClaimPerc}
                      currentValueText={`${RbClaimPerc}%`}
                      textColor={"white"}
                    />
                  )}

                  <button
                    className="btn btn-primary-bg-custom btn-sm fs-6"
                    onClick={() => {
                      if (localStorage.getItem("adsStatus") === "true") {
                        setClaimrB()

                      }
                      else {
                        alert("To Claim Watch Ad's first");
                      }
                    }}
                    disabled={
                      remainingEvcRbValue > 0 ? false : true && localStorage.getItem("adsStatus") === "false"
                    }
                    style={{
                      background: "transparent",
                      border: "1px solid",
                      width: "20vw",
                      borderRadius: "8px",
                      backgroundColor: "rgba(29, 42, 42, 1)",
                      borderColor: "#25a98e",
                      borderImageSlice: 1,
                      color: "#25a98e",
                    }}
                  >
                    {t("Claim")}
                  </button>
                  <div className="d-flex flex-row justify-content-center my-3 w-100 gap-3 flex-wrap flex-lg-nowrap">
                    <button
                      className="btn btn-primary-bg-custom btn-sm fs-6"
                      style={{
                        background: "transparent",
                        border: "1px solid",
                        width: "17vw",
                        borderRadius: "8px",
                        backgroundColor: "rgba(29, 42, 42, 1)",
                        borderColor: "#25a98e",
                        borderImageSlice: 1,
                        color: "#25a98e",
                      }}
                    >
                      {adsData?.watchedAds} {t("Ads Watched")}{" "}
                    </button>
                    <button
                      className="btn btn-primary-bg-custom btn-sm fs-6 ml-3"
                      style={{
                        background: "transparent",
                        border: "1px solid",
                        width: "17vw",
                        borderRadius: "8px",
                        backgroundColor: "rgba(29, 42, 42, 1)",
                        borderColor: "#25a98e",
                        borderImageSlice: 1,
                        color: "#25a98e",
                      }}
                    >
                      {adsData?.remainedAds} {t("Ads Remaining")}
                    </button>
                  </div>
                  <button
                    className="btn btn-primary-bg-custom btn-sm fs-6 ml-3"
                    style={{
                      background: "transparent",
                      border: "1px solid",
                      width: "9vw",
                      borderRadius: "8px",
                      backgroundColor: "rgba(29, 42, 42, 1)",
                      borderColor: "#25a98e",
                      borderImageSlice: 1,
                      color: "#25a98e",
                    }}
                    disabled={localStorage.getItem("adsStatus") === "true"}
                    onClick={() =>
                      window.open(`${VIEWERADPORTAL}`, "_blank")
                    }
                  >
                    {t("Watch Ad")}
                  </button>
                </div>
              </div>
            </div>

            {/* dev: Total Commission Earned  */}
            <div className="row mt-4 mt-lg-5">
              <div className="col-sm col-md-6 col-xl-4 mb-3 mb-xl-0">
                <div className="dash-global-wrap">
                  <div className="dash-global-inner">
                    <div className="d-flex align-items-center gap-2 mb-3 mb-md-4">
                      <div className="rank-image me-1"><img src={icon1} alt="" /></div>
                      <div className="rank-bonus line-effect text-muted">
                        {t("Total Unilevel Commission Earned")}
                        (USDC)
                      </div>
                    </div>
                    <div className="rank-bonus-num mb-2 pb-1 text-muted count-text">
                      {t("Level")} 1 - 10
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img src={busd} alt="" className="img-fluid logo-img" />
                      <span className="rank-bonus-num font-gilroy value h3 mb-0">
                        {uniLevelEarningValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm  col-md-6  col-xl-4 mb-3 mb-xl-0">
                <div className="dash-global-wrap">
                  <div className="dash-global-inner">
                    <div className="d-flex align-items-center gap-2 mb-3 mb-md-4">
                      <div className="rank-image me-1"><img src={icon2} alt="" /></div>
                      <div className="rank-bonus line-effect text-muted">
                        {t("Total Rank Bonus Commission")}
                        (USDC)
                      </div>
                    </div>
                    <div className="rank-bonus-num mb-2 pb-1 text-muted count-text">
                      {t("Rank Bonus")}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img src={busd} alt="" className="img-fluid logo-img" />
                      <span className="rank-bonus-num font-gilroy value h3 mb-0">
                        {RBEarningBUSDValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-sm col-md-6 col-xl-4">
                <div className="dash-global-wrap">
                  <div className="dash-global-inner">
                    <div className="d-flex align-items-center gap-2 mb-3 mb-md-4">
                      <div className="rank-image me-1"><img src={icon3} alt="" /></div>
                      <div className="rank-bonus line-effect text-muted">
                        {t("Total Unilevel & Rank Bonus  Commission Earned (USDC)")} (USDC)
                      </div>
                    </div>
                    <div className="rank-bonus-num mb-2 pb-1 text-muted count-text">
                      {t("Total Commission -")}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img src={busd} alt="" className="img-fluid logo-img" />
                      <span className="rank-bonus-num font-gilroy value h3 mb-0">
                        {RBEarningBUSDValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {showPopup && adsData?.length > 0 && (
              <AdPopup ads={adsData} onClose={handleClosePopup} />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankBonus;
