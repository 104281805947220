import Web3 from "web3";
import { ethers } from 'ethers';
import { ethersProvider } from "../config/context.js";

export const RPCURL = "https://sepolia.base.org";

export const BaseExplorerUrl = "https://sepolia.basescan.org/";

export const URLDOMAIN = "https://ttavatar-test.xyz/";

export const JAVAURLDOMAIN = "https://viewer.trendads.ai/";

export const CurrentChainID = 84532;

export const ContractAddressTTAVATARS = "0xBe48d9057a4be520a73A1B4774F1396cC03fdac4";

export const ContractAddressTTBLANKAVATARS = "0xFac67C9C0446d9338a671eA8448df868b64e670A";

export const ContractAddressTTAVATARSSTAKE = "0x29533194b47A9E2923830Fe4B2380582d37ff9fD";

export const ContractAddressTRNDVEST = "0x853408EB4dc116379793F86C5822C537d11224d5";

export const ContractAddressUSDC = "0x184f4db8a03fB29e42226AEc06dF185aDe39676C";

export const ContractAddressTRNDToken = "0xa33ded425b869372677dc5cf79fd78e1d1430cdd";

export const ContractAddressTrendRouter = "0x8cfe327cec66d1c090dd72bd0ff11d690c33a2eb";

export const ContractAddressPairTRNDUSDC = "0xf41129015e0463143d3cC7428f5ef637a3869149";

export const ContractAddressDead = "0x000000000000000000000000000000000000dEaD";

export const IPFSTrend = "https://ipfs.io/ipfs/QmcMJqnnQeZeNnnnDh4Dar2HuxrnibFzLxjG9BanHPcGq4/";

export const detectCurrentProvider = () => {
    let provider;
    if (window.ethereum) {
        provider = window.ethereum;
    } else if (window.web3) {
        provider = window.web3.currentProvider;
    } else {
        console.log("Non-ethereum browser detected. You should install Metamask");
    }
    return provider;
};

export const getCurrentSigner = async () => {
    try {
        if (!ethersProvider) {
            console.error("No Ethereum provider found. Please install MetaMask or another compatible wallet.");
            return null;
        }
        await ethersProvider.send("eth_requestAccounts", []);
        const { chainId } = await ethersProvider.getNetwork();
        if (chainId !== CurrentChainID) {
            await ethersProvider.send("wallet_switchEthereumChain", [{
                chainId: ethers.utils.hexlify(CurrentChainID)
            }]);
            console.log(`Switched to chain ID: ${CurrentChainID}`);
        } else {
            console.log(`Already on the correct chain ID: ${CurrentChainID}`);
        }
        const signer = ethersProvider.getSigner();
        console.log("getCurrentSigner", getCurrentSigner);
        return signer;
    } catch (error) {
        console.error("Error fetching the current signer:", error);
    }
};

export const getCurrentAccount = async () => {
    try {
        const signer = await getCurrentSigner();
        const account = await signer.getAddress();
        return account.toLowerCase();
    } catch (error) {
        console.error("Error fetching the current account:", error);
       console.log("Failed to retrieve the current account. Please try again.");
    }
}

//Sayyed code 
// export const activeCurrentChainId = async () => {
//     const web3 = new Web3(window.ethereum);
//     const currentChainId = await web3.eth.net.getId();
//     if (currentChainId !== CurrentChainID) {
//         await web3.currentProvider.request({
//             method: "wallet_switchEthereumChain",
//             params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//         });
//     }
// }

//Shreyash Code
export const activeCurrentChainId = async () => {
  try {
    if (!ethersProvider) {
      console.error("ethersProvider is not available");
      return;
    }
    const network = await ethersProvider.getNetwork();
    const currentChainId = network.chainId;
    console.log("Current Chain ID:", currentChainId);
    const targetChainIdHex = ethers.utils.hexValue(CurrentChainID); 
    if (currentChainId !== CurrentChainID) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: targetChainIdHex }],
      });
      console.log("Switched to chain:", targetChainIdHex);
    }
  } catch (error) {
    console.error("Error switching chain:", error);
  }
};


export const APIBLANKNFT = `${URLDOMAIN}api/admin/currentblanknft/`;

export const APISWAPTRADEHISTORY = `${URLDOMAIN}api/swaps`;

export const APINFTMINT = `${URLDOMAIN}api/mintnfts`;

export const APITEAMSTATISTICS = `${URLDOMAIN}api/partneractivity/getteamstatistics`; //NOTE: here should be pass user address as parameter

export const APIDIRECTTEAMSTATISTICS = `${URLDOMAIN}api/partneractivity/getdirectteamstatistics/`;

export const APIUSERJOINEDDETAILS = `${URLDOMAIN}api/mintnfts/getUserJoinedDetails/`;

export const APIJOINEDPARTNERS = `${URLDOMAIN}api/partneractivity/getjoinedPartner/`;

export const APIUSERRANK = `${URLDOMAIN}api/partneractivity/getuserrank/`;

export const APIUSERRANKBONUSES = `${URLDOMAIN}api/partneractivity/getuserrankbonuses/`;

export const APIDIRECTDETAILTEAMSTATISTICS = `${URLDOMAIN}api/partneractivity/getDirectDetailteamstatistics/`;

export const APINOTIFICATIONS = `${URLDOMAIN}api/notification/`;

export const APICHECKREFERRER = `${URLDOMAIN}api/activity/checkReffer/`;

export const APISTORETEAMSTATISTICS = `${URLDOMAIN}api/activity/storeteamstatistics/`;

export const APIUSEREFERRAL = `${URLDOMAIN}api/users/userreferral`;

export const APIDASHBOARDREF = `${URLDOMAIN}dashboard?ref=$`;

export const APIGETADS = "https://viewer.trendads.ai/api/ads/fortt/getAds/video";

export const VIEWERADPORTAL = "https://viewer.trendads.ai/";

export const DELETENOTIFICATIONBYID = `${URLDOMAIN}api/notification/deletenotification`;

export const DELETENOTIFICATIONBYADDRESS = `${URLDOMAIN}api/notification/deletenotificationbywalletaddress`;

export const JAVAADDSCOUNT = `${JAVAURLDOMAIN}api/tt/getCount/`

export const AUTHUSERNAME = "Eerd0T0Q0T27fMZdXXc+vQ==";

export const AUTHUSERPASSWORD = "ibpoza59j20AYycgvMPFBIolFhy20h7JVo4LbBTcft4=";