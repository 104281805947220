import React, { useEffect, useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  createColumnHelper,
  flexRender,
} from "@tanstack/react-table";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import {getNetworkExplorerUrl} from "../../../ContractAction/BUSDContractAction";
import TablePagination from "@mui/material/TablePagination";

const columnHelper = createColumnHelper();

const defaultColumns = [
  columnHelper.accessor("address", {
    id: "Wallet address",
    header: (props) => (
      <>
        <button onClick={props.table.getToggleAllRowsExpandedHandler()}>
          {props.table.getIsAllRowsExpanded() ? "👇" : "👉"}
        </button>
        Wallet address
      </>
    ),
    cell: ({ row, getValue }) => (
      <div
        style={{
          paddingLeft: `${row.depth * 2}rem`,
          display: "flex",
          alignItems: "center",
        }}
      >
        {row.getCanExpand() ? (
          <button
            onClick={row.getToggleExpandedHandler()}
            style={{ cursor: "pointer", marginRight: "0.5rem" }}
          >
            {row.getIsExpanded() ? "👇" : "👉"}
          </button>
        ) : (
          <span style={{ marginRight: "0.5rem" }}>🔵</span>
        )}

        <OverlayTrigger
          className="d-flex"
          placement={`top`}
          overlay={<Tooltip id={`tooltip-top`}>{getValue()}</Tooltip>}
        >
          <div style={{cursor:"pointer"}} onClick={() => getNetworkExplorerUrl(getValue())}>
            {getValue().substring(0, 4)}...
            {getValue().substring(getValue().length - 4)}
          </div>
        </OverlayTrigger>
      </div>
    ),
  }),

  columnHelper.accessor("_rank", {
    id: "Rank",
    header: "Rank",
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor("nftLevel", {
    id: "Avatar Owned",
    header: "Avatar Owned",
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor("_totalPartners", {
    id: "Total Partners",
    header: "Total Partners",
    cell: (info) => info.getValue(),
  }),

  columnHelper.group({
    id: "totalWeeklyTurnoverGroup",
    // header: "Total Weekly Turnover",
    columns: [
      columnHelper.accessor("totalTeamSalesPreviousWeekNumber", {
        id: "Last",
        header: "Last",
        cell: (info) => Number(info.getValue()).toFixed(2),
      }),
      columnHelper.accessor("totalTeamSalesLastWeekNumber", {
        id: "Current",
        header: "Current",
        cell: (info) => Number(info.getValue()).toFixed(2),
      }),
    ],
  }),

  columnHelper.accessor("totalTeamSales", {
    id: "Total Avatar Team turnover",
    header: "Total Team Turnover",
    cell: (info) => Number(info.getValue()).toFixed(2),
  }),
];

//filtered data for exact wallet address and nested wallet addresses
const filterData = (data, query) => {
  const lowerCaseQuery = query.toLowerCase();
  const result = data.reduce((acc, item) => {
    const isMatch = item.address.toLowerCase().includes(lowerCaseQuery);
    const filteredNestedData = item.nestedData
      ? filterData(item.nestedData, query)
      : [];

    if (isMatch) {
      acc.push({
        ...item,
        nestedData: item.nestedData || [],
      });
    } else if (filteredNestedData.length > 0) {
      acc.push(...filteredNestedData);
    }

    return acc;
  }, []);

  return result;
};

const BasicTable = ({ userAddress, data, setData }) => {
  const [expanded, setExpanded] = useState({});
  const [columns, setColumns] = useState([...defaultColumns]);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const filteredData = useMemo(
    () => filterData(data, searchQuery),
    [data, searchQuery]
  );

  useEffect(() => {
    if (searchQuery) {
      const updateExpanded = (data, query) => {
        const expandedState = {};
        const recursiveExpand = (rows, parentKey = "") => {
          rows.forEach((row, index) => {
            const key = parentKey ? `${parentKey}.${index}` : `${index}`;
            if (row.nestedData && row.nestedData.length > 0) {
              if (row.address.toLowerCase().includes(query.toLowerCase())) {
                expandedState[key] = true;
              }
              recursiveExpand(row.nestedData, key);
            }
          });
        };
        recursiveExpand(data);
        return expandedState;
      };
      setExpanded(updateExpanded(filteredData, searchQuery));
    } else {
      setExpanded({});
    }
  }, [filteredData, searchQuery]);

  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.nestedData,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className="table-responsive mt-4">
      <div className="search-area d-block d-md-flex justify-content-between align-items-between align-content-center mb-3 mb-md-4">
        <div className="search-title mb-2 mb-ld-0 " >{t("Partner Activity")}</div>
       <div  className="w-25 position-relative z-0" style={{padding:"5px"}}>
       <input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="form-control  w-100 ps-"
          type="search"
          placeholder="Search by address"
        />
        <span className="position-absolute z-1 top-50 start-0 translate-middle-y ps-2 pe-2">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3388_8534)">
            <path d="M2.84375 8.99935C2.84375 9.76539 2.99463 10.5239 3.28779 11.2317C3.58094 11.9394 4.01062 12.5825 4.55229 13.1241C5.09397 13.6658 5.73703 14.0955 6.44476 14.3886C7.1525 14.6818 7.91104 14.8327 8.67708 14.8327C9.44313 14.8327 10.2017 14.6818 10.9094 14.3886C11.6171 14.0955 12.2602 13.6658 12.8019 13.1241C13.3435 12.5825 13.7732 11.9394 14.0664 11.2317C14.3595 10.5239 14.5104 9.76539 14.5104 8.99935C14.5104 8.2333 14.3595 7.47476 14.0664 6.76703C13.7732 6.0593 13.3435 5.41623 12.8019 4.87456C12.2602 4.33288 11.6171 3.9032 10.9094 3.61005C10.2017 3.3169 9.44313 3.16602 8.67708 3.16602C7.91104 3.16602 7.1525 3.3169 6.44476 3.61005C5.73703 3.9032 5.09397 4.33288 4.55229 4.87456C4.01062 5.41623 3.58094 6.0593 3.28779 6.76703C2.99463 7.47476 2.84375 8.2333 2.84375 8.99935Z" stroke="#A3A3A3" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.8437 18.166L12.8438 13.166" stroke="#A3A3A3" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_3388_8534">
            <rect width="19" height="20" fill="white" transform="translate(0.34375 0.257812)"/>
            </clipPath>
            </defs>
            </svg>
          </span>
       </div>
      </div>
      <div className="table-area table-responsive">
        <p className="table-data-title mb-2">Total Weekly Turnover</p>
        <table className="table">
          <thead className="table-h hide-fist-th" style={{backgroundColor:'#42424A'}}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <tr key={row.id} className={`depth-${row.depth}`}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} data-label={cell.column.columnDef.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {/* <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot> */}
        </table>
        <div className="parpage mt-3">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
          />
        </div>
      </div>
    </div>
  );
};

export default BasicTable;
